import { string } from 'prop-types';
import { useEffect, useState } from 'react';
import styles from 'components/home-page/type-writer.module.css';

export default function TypeWriter({ size = 'large' }) {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const texts = [
      'rarely traded NFTs?',
      'art masterpieces?',
      'land on Mars?',
      'Pokémon cards?',
    ];

    // use requestAnimationFrame to increase performance
    const AniLoop = (i, j, dir, cnt) => {
      if (dir > 0) {
        if (j < texts[i % 4].length) {
          if (cnt % 5 > 0) { // pause between characters
            requestAnimationFrame(() => AniLoop(i, j, dir, cnt + 1));
          } else { // add characters
            setDisplayText(texts[i % 4].substring(0, j + 1));
            requestAnimationFrame(() => AniLoop(i, j + dir, dir, cnt + 1));
          }
        } else if (cnt < 270) { // pause at the end
          requestAnimationFrame(() => AniLoop(i, j, dir, cnt + 1));
        } else { // start deleting
          setDisplayText(texts[i % 4].substring(0, j + 1));
          requestAnimationFrame(() => AniLoop(i, j + dir, -1, 0));
        }
      } else if (dir < 0) {
        if (j >= 0) {
          if (cnt % 3 > 0) { // pause between characters
            requestAnimationFrame(() => AniLoop(i, j, dir, cnt + 1));
          } else { // delete characters
            setDisplayText(texts[i % 4].substring(0, j));
            requestAnimationFrame(() => AniLoop(i, j + dir, dir, cnt + 1));
          }
        } else if (cnt < 120) { // pause at the end
          requestAnimationFrame(() => AniLoop(i, j, dir, cnt + 1));
        } else { // start next text
          setDisplayText(texts[i % 4].substring(0, j));
          requestAnimationFrame(() => AniLoop(i + 1, j + dir, 1, 0));
        }
      }
    };
    AniLoop(0, 0, 1, 0);

    return () => setDisplayText('');
  }, []);

  return (
    <p
      className={`${styles.typewriter} text-white p-0 m-0`}
      style={{
        fontSize: size === 'large' ? '48px' : '32px',
      }}
    >
      {displayText}
    </p>
  );
}

TypeWriter.propTypes = {
  size: string,
};
