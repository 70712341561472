import { number } from 'prop-types';
import Image from 'next/image';
import styles from 'components/home-page/fading-rects.module.css';

import BannerBgPatternLong1 from '../../../public/static/images/landing/banner-background-icon-long-1.svg';
import BannerBgPatternLong2 from '../../../public/static/images/landing/banner-background-icon-long-2.svg';

import BannerBgPatternShort1 from '../../../public/static/images/landing/banner-background-icon-short-1.svg';
import BannerBgPatternShort2 from '../../../public/static/images/landing/banner-background-icon-short-2.svg';

export function FadingRectsLong({
  rotate = 0,
}) {
  return (
    <div className="relative">
      <div
        className="absolute"
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <Image
          src={BannerBgPatternLong1}
          className={`${styles.rect}`}
          loading="eager"
          quality={50}
        />
      </div>
      <div
        className="absolute"
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <Image
          src={BannerBgPatternLong2}
          className={`${styles.rectAlt}`}
          loading="eager"
          quality={50}
        />
      </div>
    </div>
  );
}

FadingRectsLong.propTypes = {
  rotate: number,
};

export function FadingRectsShort({
  rotate = 0,
}) {
  return (
    <div className="relative">
      <div
        className="absolute"
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <Image
          src={BannerBgPatternShort1}
          className={styles.rect}
          loading="eager"
          quality={50}
        />
      </div>
      <div
        className="absolute"
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <Image
          src={BannerBgPatternShort2}
          className={styles.rectAlt}
          loading="eager"
          quality={50}
        />
      </div>
    </div>
  );
}

FadingRectsShort.propTypes = {
  rotate: number,
};

export function FadingRectsBackground() {
  return (
    <div className="w-full h-full absolute opacity-30">
      {/* top left */}
      <div className="-top-[4rem] -left-[7rem] relative">
        <FadingRectsLong />
      </div>
      {/* top right */}
      <div className="left-[65rem] relative">
        <FadingRectsShort rotate={180} />
      </div>
      <div className="top-[1rem] left-[71.2rem] relative">
        <FadingRectsShort rotate={-90} />
      </div>
      {/* bottom left */}
      <div className="top-[38rem] -left-[27rem] relative">
        <FadingRectsLong />
      </div>
      {/* bottom center */}
      <div className="top-[38rem] left-[23rem] relative">
        <FadingRectsShort />
      </div>
      {/* bottom right */}
      <div className="top-[22rem] left-[45rem] relative">
        <FadingRectsShort />
      </div>
      <div className="top-[27.2rem] left-[38.5rem] relative">
        <FadingRectsLong />
      </div>
      <div className="top-[29.2rem] left-[43.7rem] relative">
        <FadingRectsLong rotate={180} />
      </div>
    </div>
  );
}

export function FadingRectsBackgroundMd() {
  return (
    <div className="w-full h-full absolute opacity-30">
      {/* top left */}
      <div className="top-[2rem] -left-[4rem] relative">
        <FadingRectsLong />
      </div>
      <div className="top-[4.6rem] left-[5.2rem] relative">
        <FadingRectsLong />
      </div>
      {/* top right */}
      <div className="-top-[4rem] left-[36rem] relative">
        <FadingRectsShort rotate={-90} />
      </div>
      {/* right center */}
      <div className="top-[15rem] left-[27rem] relative">
        <FadingRectsLong />
      </div>
      <div className="top-[17rem] left-[15.1rem] relative">
        <FadingRectsLong rotate={180} />
      </div>
      {/* left center */}
      <div className="top-[18.9rem] left-[3rem] relative">
        <FadingRectsShort />
      </div>
      {/* bottom right */}
      <div className="top-[27rem] left-[28rem] relative">
        <FadingRectsShort rotate={180} />
      </div>
      {/* bottom left */}
      <div className="top-[38.5rem] -left-[1rem] relative">
        <FadingRectsLong rotate={180} />
      </div>
    </div>
  );
}
