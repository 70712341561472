import { animated, useSpring } from 'react-spring';
import { objectOf, string } from 'prop-types';
import { random } from 'lodash';
import { useEffect, useRef, useState } from 'react';

function getStep(x, stepSize = 100) {
  return Math.floor(x / stepSize);
}

function NumberCounterCharacter({
  initCharacter = '$',
  character,
}) {
  const [lowerLetter, setUpperLetter] = useState(initCharacter);
  const [upperLetter, setLowerLetter] = useState(0);

  const lastX = useRef(0);

  const [steps, setSteps] = useState(0);
  const [isUpward, setIsUpward] = useState(0);

  useEffect(() => {
    setLowerLetter(random(0, 9));
    setSteps(random(20, 40));
    setIsUpward(Math.random() < 0.5);
  }, []);

  const { x } = useSpring({
    delay: 1000,
    from: { x: 0 },
    to: { x: steps * 100 },
    config: {
      friction: random(20, 30),
      tension: random(50, 80),
    },
    onChange: ({ value: { x: currX } }) => {
      if (getStep(currX) > getStep(lastX.current)) {
        if (getStep(currX) % 2 === 0) {
          if (getStep(currX) + 1 === steps) setLowerLetter(character);
          else setLowerLetter(random(0, 9));
        } else {
          // eslint-disable-next-line no-lonely-if
          if (getStep(currX) + 1 === steps) setUpperLetter(character);
          else setUpperLetter(random(0, 9));
        }
        lastX.current = currX;
      }
    },
  });

  return (
    <span className="flex overflow-hidden relative" style={{ width: character === '%' ? '1.3ch' : '1ch' }}>
      <animated.span
        className="absolute left-1/2"
        style={{
          transform: isUpward
            ? x.to((_) => `translate(-50%, ${-((_ + 100) % 200) + 100}%)`)
            : x.to((_) => `translate(-50%, ${((_ + 100) % 200) - 100}%)`),
        }}
      >
        {lowerLetter}
      </animated.span>
      <animated.span
        className="absolute left-1/2"
        style={{
          transform: isUpward
            ? x.to((_) => `translate(-50%, ${-(_ % 200) + 100}%)`)
            : x.to((_) => `translate(-50%, ${(_ % 200) - 100}%)`),
        }}
      >
        {upperLetter}
      </animated.span>
      <span className="invisible">{character}</span>
    </span>
  );
}

NumberCounterCharacter.propTypes = {
  initCharacter: string,
  character: string,
};

function NumberCounterText({
  className,
  text,
  initialText,
  style,
}) {
  return (
    <span className={`inline-flex overflow-hidden ${className}`} style={style}>
      {
        text.split('').map((char, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <NumberCounterCharacter key={i} initCharacter={initialText?.[i]} character={char} />
        ))
      }
    </span>
  );
}

NumberCounterText.propTypes = {
  className: string,
  text: string,
  initialText: string,
  style: objectOf(string),
};

export default NumberCounterText;
