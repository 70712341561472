import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { arrayOf, string } from 'prop-types';
import { useCallback, useContext, useState } from 'react';
import Title from 'antd/lib/typography/Title';
// import mixpanel from 'libs/mixpanel';
// contexts
import WindowSizeContext from 'contexts/window-size';

export const FAQ_TEXT = [
  {
    title: 'What is Lithium?',
    answer: [
      'Decentralized Valuation powered by Artificial Intelligence and Crowd Wisdom.',
      'We help people understand how much digital assets are worth so that they could be put to good use.',
    ],
  },
  {
    title: 'How does Lithium work?',
    answer: [
      'The protocol incentivizes and aggregates opinion from the community to gauge market sentiment.',
      'By combining Artificial Intelligence and Crowd Wisdom, Lithium produces decentralized, reliable, accurate, and efficient valuation.',
    ],
  },
  {
    title: 'What is the utility of LITH token?',
    answer: [
      "LITH is Lithium's native token.  Rewards, Staking, and Governance within the ecosystem will be denominated in LITH.",
      'Stay tuned for updates.',
    ],
  },
  // {
  //   title: 'How to get involved?',
  //   answer: [
  //     'Join the Price Expert community!',
  //     'Earn Rewards by sharing your market insight.',
  //     "Let's help the web3 community better understand NFT value.",
  //   ],
  // },
  {
    title: 'How do we produce valuation?',
    answer: [
      'Our quantitative AI models analyzed millions worth of trading data to produce a base valuation.',
      'When Artificial Intelligence doesn’t perform ideally for rare NFTs without sufficient trading data, we supplement that with crowd wisdom from the community.',
    ],
  },
  // {
  //   title: 'Where to get LITH token?',
  //   answer: [
  //     'Huobi Global, CoinEx, Uniswap, OKEx, KuCoin, MEXC Exchange, gate.io, Bilaxy, BitMart, Bitkan, ZT Global, Decoin.io, and more…',
  //   ],
  // },
];

export const HIW_FAQ_TEXT = [
  {
    title: 'What is Price2Earn?',
    answer: (
      <span>Price2Earn is a new reward model where community members act as Price Expert
        to submit their estimates on NFTs to Earn rewards
        risk free by joining Lithium’s Pricing Quest.
      </span>
    ),
  },
  {
    title: 'What is a Pricing Quest?',
    answer: (
      <span>A Pricing Quest consists of at least 5 NFTs for Price Experts
        to evaluate and submit their valuation estimates on.
      </span>
    ),
  },
  {
    title: 'How many NFTs are there in a Pricing Quest?',
    answer: <span>Five or more NFTs per Pricing Quest</span>,
  },
  {
    title: 'What token do I need to use to participate in the Pricing Quest?',
    answer: <span>LITH or MATIC on Polygon</span>,
  },
  {
    title: 'Is there a minimum token I need to stake?',
    answer: (
      <span>You can stake as low as 0.1 LITH to join Pricing Quest.
        The more you stake, the more you earn when you win.
      </span>
    ),
  },
  {
    title: 'Will I lose my stake when participating in a Pricing Quest?',
    answer: (
      <span>No, Pricing Quest is risk free.
        You will be able to claim back all the staked tokens at My Submission.
      </span>
    ),
  },
  {
    title: 'What token will I earn from the reward pool?',
    answer: <span>LITH on Polygon.</span>,
  },
  {
    title: 'How do I know I have won?',
    answer: (
      <span>You can view the results at My Submission.
        If you have subscribed for our notification e-mail,
        you will receive a notification upon Pricing Quest result is published.
      </span>
    ),
  },
  {
    title: 'Will the staked tokens return to my wallet automatically after Pricing Quest is closed?',
    answer: (
      <span>No, you are required to claim the token at
        My Submission together with claiming Reputation Points
      </span>
    ),
  },
  {
    title: 'How to claim token?',
    answer: <span>Claim you token at My Submission.</span>,
  },
  // {
  //   title: 'Where can I buy token?',
  //   answer: (
  //     <span>{'LITH is available on major exchanges. Check out the guide on '}
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://docs.lith.finance/protocol/reward-mechanism#reputation-points"
  //         className="underline text-current"
  //         onClick={() => {
  //           mixpanel.track(
  //             '👆🏼HIW FAQ Get LITH',
  //             { location: 'HIW_FAQs' },
  //             { send_immediately: true },
  //           );
  //         }}
  //       >
  //         Where to get LITH
  //       </a>.
  //     </span>
  //   ),
  // },
];

function Row({ title, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const { xs, sm } = useContext(WindowSizeContext);

  return (
    <li className="w-full">
      <button
        className={`
        rounded-2xl bg-[#1E2024] text-white p-4 w-full flex flex-col mb-4
        ${xs || sm ? 'text-base' : 'text-[24px]'}
        `}
        onClick={onClick}
        type="button"
      >
        <div className="flex justify-between font-bold items-center w-full">
          {title}
          {isOpen
            ? <ArrowUpCircleIcon width="20px" height="20px" className="mr-2 inline text-white" />
            : <ArrowDownCircleIcon width="20px" height="20px" className="mr-2 inline text-white" />}
        </div>
        {isOpen && (
          <ul className="ml-4 list-disc mb-0 mt-4 text-left text-base font-[WorkSans]">
            {answer.map((d) => <li key={d}>{d}</li>)}
          </ul>
        )}
      </button>
    </li>
  );
}

Row.propTypes = {
  title: string.isRequired,
  answer: arrayOf(string).isRequired,
};

function Faq() {
  const { xs, sm, md } = useContext(WindowSizeContext);

  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <Title type="secondary" level={2} className={`mb-0 font-bold ${sm || xs ? 'text-[24px]' : 'text-[32px]'}`}>
        Frequently Asked Questions
      </Title>
      <div className="bg-transparent text-white flex flex-col items-center mt-[16px] md:mt-[32px]">
        <ul className={`${xs || sm || md ? 'w-full' : 'w-[800px]'}`}>
          {FAQ_TEXT.map(({ title, answer }) => (
            <Row title={title} answer={answer} key={title} />
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Faq;
