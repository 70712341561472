import { Button, Collapse, Popover, Tabs } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { bool } from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Title from 'antd/lib/typography/Title';

import WindowSizeContext from 'contexts/window-size';

import CollectiblesTab from '../../../public/static/images/landing/collectibles-tab.svg';

import DealSourcingIcon from '../../../public/static/images/landing/deal-sourcing-icon.svg';
import LendingIcon from '../../../public/static/images/landing/lending-icon.svg';
import NftAnalyticsIcon from '../../../public/static/images/landing/nft-analytics-icon.svg';
import TrustlessSwapIcon from '../../../public/static/images/landing/trustless-swap-icon.svg';
import ValuationIcon from '../../../public/static/images/landing/valuation-icon.svg';

import DealSourcingImg from '../../../public/static/images/landing/deal-sourcing-img.svg';
import LendingImg from '../../../public/static/images/landing/lending-img.svg';
import NftAnalyticsImg from '../../../public/static/images/landing/nft-analytics-img.svg';
import PeasySwapImg from '../../../public/static/images/landing/peasy-swap-img.png';
import ValuationImg from '../../../public/static/images/landing/valuation-img.svg';

const { Panel } = Collapse;

function ComingSoon() {
  return <span>Coming soon!</span>;
}

function Collectibles() {
  const { xs, sm } = useContext(WindowSizeContext);
  return (
    <div
      className="bg-[#2E2F3A] rounded-bl-2xl rounded-br-2xl text-[#FFFCFB]"
      style={
        xs || sm
          ? { padding: '16px' }
          : { padding: '32px' }
      }
    >
      <Title level={3} className="text-[#FFFCFB] mb-8">Expert appraisal for collectibles</Title>
      <div className="flex gap-8">
        <div className="bg-[#1E2024] p-8 rounded-2xl flex flex-col w-full items-center relative">
          <div
            className="relative mb-8"
            style={
              (xs || sm)
                ? { width: '250px', height: '230px' }
                : { width: '450px', height: '310px' }
            }
          >
            <Image src={CollectiblesTab} layout="fill" />
          </div>
          <span className="text-xl font-bold mb-4">Get AI and expert insight on antique cars, watches, trading cards, and more.</span>
          <span className="text-lg font-normal text-[#D2D3DD] font-[WorkSans]">Coming soon!</span>
        </div>
      </div>
    </div>
  );
}

function ExpandIcon({ isActive }) {
  return <DownOutlined rotate={isActive ? 180 : 0} className="text-white" size={24} />;
}

ExpandIcon.propTypes = {
  isActive: bool,
};

function Nfts() {
  const [activeButton, setActiveButton] = useState('0-NFT Analytics');
  const { xs, sm } = useContext(WindowSizeContext);
  const iconSize = xs || sm ? 24 : 40;
  const imgSize = xs || sm ? 180 : 250;
  const supports = [
    {
      image: (
        <div
          className="w-full relative"
          style={{ height: `${imgSize}px` }}
        >
          <Image
            src={NftAnalyticsImg}
            layout="fill"
          />
        </div>
      ),
      icon: (
        <Image
          src={NftAnalyticsIcon}
          height={iconSize}
          width={iconSize}
        />
      ),
      title: 'NFT Analytics',
      desc: [
        'Concise market overview at a glance',
        'Focus on specific market segments you need',
        "Powered by Lithium Finance's AI & Crowd Wisdom Valuation",
      ],
      cta: (
        <Link href="https://nftcheatsheet.ai" passHref>
          <Button
            type="primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Launch App
          </Button>
        </Link>
      ),
      key: '0-NFT Analytics',
    },
    {
      image: (
        <div
          className="w-full relative"
          style={{ height: `${imgSize}px` }}
        >
          <Image
            src={ValuationImg}
            layout="fill"
          />
        </div>
      ),
      icon: (
        <Image
          src={ValuationIcon}
          height={iconSize}
          width={iconSize}
        />
      ),
      title: 'Valuation',
      desc: [
        'Appraisal from AI and collective intelligence of the community',
        'Customized valuation to suit your specific needs',
        'API key available to select partners',
      ],
      cta: (
        <Link href="/app/quests" passHref>
          <Button
            type="primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Launch App
          </Button>
        </Link>
      ),
      key: '1-Valuation',
    },
    {
      image: (
        <div
          className="relative mb-[50px]"
        >
          <Image
            src={PeasySwapImg}
          />
        </div>
      ),
      icon: (
        <Image
          src={TrustlessSwapIcon}
          height={iconSize}
          width={iconSize}
        />
      ),
      title: 'Trustless Swap',
      desc: [
        'Secured decentralized swapping service to keep your funds and NFTs safe during trade',
        'All assets remain in your wallet at all time. Transact only if everyone has executed their end of the deal in the same block',
        'Support any ERC20, ERC721 and ERC1155 assets with lowest gas',
      ],
      cta: (
        <Link href="https://peasyswap.xyz" passHref>
          <Button
            type="primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Launch App
          </Button>
        </Link>
      ),
      key: '2-Trustless Swap',
    },
    {
      image: (
        <div
          className="w-full relative"
          style={{ height: `${imgSize}px` }}
        >
          <Image
            src={DealSourcingImg}
            layout="fill"
          />
        </div>
      ),
      icon: (
        <Image
          src={DealSourcingIcon}
          height={iconSize}
          width={iconSize}
        />
      ),
      title: 'Deal Sourcing',
      desc: [
        'Front run marketplaces. Getting you a good deal before they are even listed',
        'Hooking you up with potential buyers and sellers privately',
        'Expert advise to get you the best deals',
      ],
      cta: (
        <Link href="https://hiq83k1k70o.typeform.com/to/suLgZvq6" passHref>
          <Button
            type="primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact Us
          </Button>
        </Link>
      ),
      key: '3-Deal Sourcing',
    },
    {
      image: (
        <div
          className="w-full relative"
          style={{ height: `${imgSize}px` }}
        >
          <Image
            src={LendingImg}
            layout="fill"
          />
        </div>
      ),
      icon: (
        <Image
          src={LendingIcon}
          height={iconSize}
          width={iconSize}
        />
      ),
      title: 'Lending',

      desc: [
        'Access liquidity without selling',
        'Use NFTs as collateral for loans',
        'Peer - to - Pool and Peer - to - Peer options',
      ],
      cta: (
        <Link href="mailto: hello@lith.finance" passHref>
          <Button
            type="primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact Us
          </Button>
        </Link>
      ),
      key: '4-Lending',
    },
  ];
  if (xs || sm) {
    return (
      <div className="bg-[#2E2F3A] rounded-bl-2xl rounded-br-2xl p-4 text-[#FFFCFB]">
        <Title level={3} className="text-[#FFFCFB] mb-6">Supporting the NFT Ecosystem</Title>
        <div className="flex flex-col gap-2">
          <Collapse
            accordion
            // defaultActiveKey={'0-NFT Analytics'}
            activeKey={activeButton}
            expandIconPosition="end"
            expandIcon={ExpandIcon}
            className="bg-transparent"
            onChange={(key) => setActiveButton(key || activeButton)}
          >
            {supports.map((item) => (
              <Panel
                key={item.key}
                className="text-[#FFFCFB] rounded-lg relative my-2"
                style={
                  item.key === activeButton
                    ? { backgroundColor: '#1E2024', border: '1px solid #FFFCFB' }
                    : { backgroundColor: '#5B5C6C', border: '1px solid transparent' }
                }
                header={
                  (
                    <div
                      className="flex items-center text-lg font-medium gap-2 text-[#FFFCFB]"
                      style={
                        item.key === activeButton
                          ? { backgroundColor: '#1E2024' }
                          : { backgroundColor: '#5B5C6C' }
                      }
                    >
                      {item.icon}
                      {item.title}
                    </div>
                  )
                }
              >
                <div className="flex flex-col bg-[#1E2024] w-full">
                  {supports.at(activeButton[0])?.image}
                  <ul className="list-disc ml-6 font-[WorkSans] text-[#FFFCFB]">
                    {supports.at(activeButton[0]).desc.map((d) => <li key={`desc-${d.slice(5)}`}>{d}</li>)}
                  </ul>
                  {supports.at(activeButton[0]).cta}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-[#2E2F3A] rounded-bl-2xl rounded-br-2xl p-8 text-[#FFFCFB]">
      <Title level={3} className="text-[#FFFCFB] mb-8">Supporting the NFT Ecosystem</Title>
      <div className="flex gap-8">
        <div className="w-1/2 flex flex-col gap-4">
          {supports.map((item) => (
            <Button
              key={item.key}
              className="flex items-center text-[#FFFCFB] px-6 py-10 gap-4 rounded-2xl hover:bg-[#E96036] text-xl font-semibold"
              icon={item.icon}
              style={
                item.key === activeButton
                  ? { backgroundColor: '#1E2024', border: '1px solid #FFFCFB' }
                  : { backgroundColor: '#5B5C6C', border: '1px solid transparent' }
              }
              onClick={() => setActiveButton(item.key)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <div className="border border-[#FFFCFB] rounded-2xl flex flex-col justify-center px-6 py-4 w-1/2 bg-[#1E2024]">
          {supports.at(activeButton[0])?.image}
          <ul className="list-disc ml-6 font-[WorkSans]">
            {supports.at(activeButton[0]).desc.map((d) => <li key={`desc-${d.slice(5)}`}>{d}</li>)}
          </ul>
          {supports.at(activeButton[0]).cta}
        </div>
      </div>
    </div>
  );
}

function UseCases() {
  const { xs, sm } = useContext(WindowSizeContext);
  const ref = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    return () => setWidth(null);
  }, []);

  const items = useMemo(() => [
    {
      label: (
        <div
          className="flex items-center justify-center rounded-tl-2xl rounded-tr-2xl"
          style={
            (xs || sm)
              ? { fontSize: '16px', width: (((width ?? 0) * 2) / 3), height: '64px' }
              : { fontSize: '24px', width: ((width ?? 0) / 4 - 12), height: '64px' }
          }
        >
          NFTs
        </div>
      ),
      key: 'NFTs',
      children: <Nfts />,
    },
    {
      label: (
        <div
          className="flex items-center justify-center rounded-tl-2xl rounded-tr-2xl"
          style={
            (xs || sm)
              ? { fontSize: '16px', width: (((width ?? 0) * 2) / 3), height: '64px' }
              : { fontSize: '24px', width: ((width ?? 0) / 4 - 12), height: '64px' }
          }
        >
          Collectibles
        </div>
      ),
      key: 'Collectibles',
      children: <Collectibles />,
    },
    {
      label: (
        <Popover content={<ComingSoon />}>
          <div
            className="flex items-center justify-center rounded-tl-2xl rounded-tr-2xl"
            style={
              (xs || sm)
                ? { fontSize: '16px', width: (((width ?? 0) * 2) / 3), height: '64px' }
                : { fontSize: '24px', width: ((width ?? 0) / 4 - 12), height: '64px' }
            }
          >
            Arts
          </div>
        </Popover>
      ),
      key: 'Arts',
      children: <Nfts />,
      disabled: true,
    },
    {
      label: (
        <Popover content={<ComingSoon />}>
          <div
            className="flex items-center justify-center rounded-tl-2xl rounded-tr-2xl"
            style={
              (xs || sm)
                ? { fontSize: '16px', width: (((width ?? 0) * 2) / 3), height: '64px' }
                : { fontSize: '24px', width: ((width ?? 0) / 4 - 12), height: '64px' }
            }
          >
            Exotic Assets
          </div>
        </Popover>
      ),
      key: 'Exotic Assets',
      children: <Nfts />,
      disabled: true,
    }], [sm, xs, width]);

  return (
    <section
      id="ecosystem"
      className="max-w-[1200px] mx-auto mt-20 justify-between flex flex-col relative text-[#FFFCFB]"
    >
      <span className="text-3xl font-bold pb-8" ref={ref}>Use Cases</span>
      <div>
        <Tabs
          defaultActiveKey="Rare NFTs"
          type="card"
          items={items}
        />
      </div>
    </section>
  );
}

export default UseCases;
