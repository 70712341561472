/* eslint-disable @next/next/no-img-element */
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { BLURB_LIMIT, TITLE_LIMIT } from 'components/home-page/medium-item';
import { Button, Typography } from 'antd';
import { LIST } from 'components/home-page/learn-more';
import { Swiper } from 'antd-mobile';
import { string } from 'prop-types';
import _ from 'lodash';

const { Title, Paragraph } = Typography;

function Item({ title, blurb, href, img }) {
  return (
    <li className="w-[350px] p-2">
      <img
        alt=""
        src={img}
        className="rounded-2xl border border-[#8687A0] border-solid"
      />
      <a
        className="flex items-center text-white hover:underline font-bold"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Title className="font-bold m-0 text-2xl text-white mt-4" level={3}>
          {_.truncate(title, { length: TITLE_LIMIT })}
        </Title>
      </a>
      <Paragraph className="text-white mt-2 font-[WorkSans]">
        {_.truncate(blurb, { length: BLURB_LIMIT })}
      </Paragraph>
    </li>
  );
}

Item.propTypes = {
  title: string.isRequired,
  blurb: string.isRequired,
  href: string.isRequired,
  img: string.isRequired,
};

function LearnMore() {
  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <div className="flex items-center justify-between mb-4">
        <Title type="secondary" level={2} className="font-bold text-[24px] mb-0">
          Learn More
        </Title>
        <Button
          type="text"
          href="https://medium.com/lithiumfinance"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center mt-2"
        >
          Open Medium
          <ArrowLongRightIcon className="ml-0.5 h-5 w-5" />
        </Button>
      </div>
      <ul className="">
        <Swiper
          className="overflow-visible mb-6"
          stuckAtBoundary={false}
          indicatorProps={{
            style: {
              transform: 'translateY(30px)',
              transition: 'all 400ms',
              '--dot-color': 'rgba(252, 252, 252, 0.3)',
              '--active-dot-color': 'rgba(252, 252, 252, 1)',
              '--active-dot-size': '30px',
              '--dot-border-radius': '1.5px',
              '--dot-spacing': '8px',
            },
          }}
        >
          {LIST.map((d) => (
            <Swiper.Item key={d.img}>
              <Item {...d} />
            </Swiper.Item>
          ))}
        </Swiper>
      </ul>
    </section>
  );
}

export default LearnMore;
