/* eslint-disable @next/next/no-img-element */
import { useContext } from 'react';

// contexts
import { Carousel } from 'antd';
import WindowSizeContext from 'contexts/window-size';

import _ from 'lodash';

const MEDIA = [
  { name: 'The Block', img: 'the-block', href: 'https://www.theblock.co/linked/106747/defi-oracle-protocol-lithium-5-million-pantera-hashed' },
  { name: 'Coindesk', img: 'coindesk', href: 'https://www.coindesk.com/business/2021/06/01/lithium-raises-5m-for-decentralized-oracle-tracking-private-assets' },
  { name: 'Cointelegraph', img: 'cointelegraph', href: 'https://cointelegraph.com/news/defi-needs-more-tangible-assets-on-chain-to-see-a-successful-future' },
  { name: 'Forkast', img: 'forkast-news', href: 'https://forkast.news/video-audio/crypto-war-ukraine-russia/' },
  { name: 'The Defiant', img: 'the-defiant' },
  { name: 'Yahoo! Finance', img: 'yahoo', href: 'https://finance.yahoo.com/news/lithium-finance-founder-incentivising-collective-150000351.html' },
  { name: 'Morningstar Asia', img: 'morningstar', href: 'https://hk.morningstar.com/AP/MEDIAOUTREACH/DEFAULT.ASPX?CLANG=ZH-HK&release_id=184879' },
  { name: 'Bloomberg', img: 'bloomberg' },
  { name: 'Dow Jones Newswires', img: 'dowjones_newswires' },
  { name: 'Daily Bulletin', img: 'daily_bulletin', href: 'https://dailybulletin.com.au/business/news-from-asia/70671-lithium-finance-announcing-its-mainnet-beta-launch' },
  { name: 'The Times', img: 'the_times', href: 'https://thetimes.com.au/news/news-from-asia/18984-lithium-finance-announcing-its-mainnet-beta-launch' },
  { name: 'International Business Times', img: 'international_business_times', href: 'https://markets.financialcontent.com/ibtimes/article/newsdirect-2022-12-8-lithium-finance-announcing-its-mainnet-beta-launch' },
  { name: 'ET Net', img: 'etnet', href: 'https://www.etnet.com.hk/www/tc/news/mediaoutreach_news_detail.php?newsid=184879&page=1' },
  { name: 'Crypto Insider', img: 'crypto_insider', href: 'https://cryptoinsider.asia/media-outreach/?release_id=184879' },
  { name: 'Washington Daily News', img: 'washington_daily_news', href: 'https://smb.thewashingtondailynews.com/article/Lithium-Finance-announcing-its-Mainnet-Beta-Launch?storyId=63916e9caaf944db5d0e1701' },

];

function SeenOn() {
  const { xs, sm } = useContext(WindowSizeContext);

  return (
    <section className="px-[4%] flex mt-20 flex-col justify-center items-center">
      <div className={`text-[#8687A0] font-medium ${xs || sm ? 'text-[18px]' : 'text-[24px] mb-6'}`}>
        As Seen On
      </div>
      <div className="w-full">
        <Carousel
          autoplay
          style={
            xs || sm
              ? { height: '160px' }
              : { height: '200px' }
          }
        >
          {_.chunk(MEDIA, (xs || sm ? 2 : 5)).map((list) => (
            <div key={`list-${list[0].name}`} className="flex items-center justify-between">
              {list.map((m) => (
                <a
                  href={m.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-6 max-w-[220px] max-h-[125px] px-4 py-6 self-center"
                  key={m.img}
                >
                  <img
                    style={{
                      objectFit: 'fill',
                    }}
                    alt={m.name}
                    src={`/static/images/media/${m.img}.png`}
                    key={m.name}
                  />
                </a>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default SeenOn;
