import { useQuery } from 'react-query';
import { useRouter } from 'next/router';

import { questApi } from 'api/fetchers';

export default function useLandingPageStatsQuery() {
  const router = useRouter();
  const { isReady: isRouterReady } = router;

  const {
    data = {},
    ...values
  } = useQuery(
    'LANDING_PAGE_QUEST_STATS',
    async () => {
      const { data: _data } = await questApi.getQuestStats();

      return _data;
    },
    {
      enabled: isRouterReady,
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    ...values,
  };
}
