import { formatNumbro } from 'utils';
import { useContext } from 'react';
import BigNumber from 'libs/bignumber';

// components
import NumberCounterText from 'components/number-counter-text';

// contexts
import WindowSizeContext from 'contexts/window-size';

// hooks
import useLandingPageStatsQuery from 'hooks/queries/use-landing-page-stats-query';

function Stats() {
  const { xs, sm } = useContext(WindowSizeContext);
  const {
    data: {
      rewardDistributed = '',
      expertCount = '',
    },
  } = useLandingPageStatsQuery();

  const STATS = [
    // quest rewards + pre staking rewards
    { title: 'LITH Reward Distributed', value: BigNumber(rewardDistributed).plus(28858953).toFixed(), init: '--,---,---' },
    { title: 'Experts Recruited', value: expertCount, init: '--,---' },
    { title: 'Valuation Accuracy', value: '90%+', init: '----' },
    { title: 'Transactions Analyzed', value: 'Ξ10M+', init: '----' },
  ];

  return (
    <section className="max-w-[1200px] mx-auto mt-10 flex justify-center flex-wrap">
      {STATS.map((s) => (
        <div className={`flex flex-col font-bold items-center text-white mt-6 ${xs || sm ? 'mx-6' : 'mx-10'}`} key={s.title}>
          <div className="z-10">
            <NumberCounterText
              className="font-bold text-[32px] md:text-[48px]"
              text={formatNumbro(s.value)}
              initialText={s.init}
            />
          </div>
          <div className={`text-gray font-medium ${xs ? 'text-[12px]' : 'text-[18px]'}`}>
            {s.title}
          </div>
        </div>
      ))}
    </section>
  );
}

export default Stats;
