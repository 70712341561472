/* eslint-disable @next/next/no-img-element */
import { Button, Modal } from 'antd';
import { PlayIcon } from '@heroicons/react/24/outline';
import { useContext, useRef, useState } from 'react';
import Image from 'next/image';

// contexts
import WindowSizeContext from 'contexts/window-size';

import AiXCrowdWisdom from '../../../public/static/images/landing/ai-x-crowd-wisdom.svg';

const YOUTUBE_LINK = 'https://www.youtube.com/embed/6dkAlUSGvHk';

function HowItWorks() {
  const { xs, sm } = useContext(WindowSizeContext);
  const [showModal, setShowModal] = useState(false);
  const iframeContainerRef = useRef(null);
  const iframeRef = useRef(null);

  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <Modal
        destroyOnClose
        open={showModal}
        footer={null}
        centered
        closable
        onCancel={() => setShowModal(false)}
        className="w-[608px] h-[363px]"
        bodyStyle={{
          backgroundColor: '#000000',
        }}
      >
        <div
          ref={iframeContainerRef}
        >
          <iframe
            ref={iframeRef}
            width={(xs || sm) ? window.innerWidth - 40 : 560}
            height={xs || sm ? undefined : 315}
            src={YOUTUBE_LINK}
            title="How Lithium Finance works?"
            frameBorder="0"
            allow={`
              accelerometer;
              autoplay;
              clipboard-write;
              encrypted-media;
              gyroscope;
              picture-in-picture
            `}
            allowFullScreen
          />
        </div>
      </Modal>
      <div
        className="font-semibold text-[#FFFCFB]"
        style={{
          fontSize: (xs || sm) ? '24px' : '32px',
          marginTop: (xs || sm) ? '0px' : '32px',
        }}
      >
        AI & Crowd Wisdom
      </div>
      <div className={`${xs || sm ? 'h-[276px] mt-6' : 'h-[832px] mt-8'} relative w-full bg-[#1E2024] rounded-xl`}>
        <Image src={AiXCrowdWisdom} layout="fill" />
      </div>
      <div className={`flex items-center justify-center mt-2 ${xs || sm ? 'text-[12px]' : 'text-[16px]'}`}>
        <PlayIcon
          width={xs || sm ? '12px' : '20px'}
          height={xs || sm ? '12px' : '20px'}
          className="mr-1 inline text-white"
        />
        <span className="text-white">
          Watch detail explanation here!
        </span>
        <Button
          className="flex items-center px-4 py-4"
          type="text"
          size="small"
          onClick={() => setShowModal(true)}
        >
          Play Video
        </Button>
      </div>
    </section>
  );
}

export default HowItWorks;
