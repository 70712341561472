import { Button, Modal } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Title from 'antd/lib/typography/Title';

// contexts
import WindowSizeContext from 'contexts/window-size';

// components
import { FadingRectsBackground, FadingRectsBackgroundMd } from 'components/home-page/fading-rects';

import CrowdWisdom from '../../../public/static/images/landing/crowd-wisdom.svg';
import TypeWriter from './type-writer';

const YOUTUBE_LINK = 'https://www.youtube.com/embed/Y7bP_p2UUkQ';

function BannerSection() {
  const { xs, sm, width } = useContext(WindowSizeContext);
  const [showModal, setShowModal] = useState(false);
  const iframeContainerRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeContainerRef.current && iframeRef.current) {
      iframeRef.current.width = iframeContainerRef.current.clientWidth;
      iframeRef.current.height = iframeContainerRef.current.clientHeight;
    }
  }, [width]);

  if (xs || sm) {
    return (
      <section className="max-w-[1200px] mx-auto justify-between flex flex-col-reverse relative">
        <Modal
          destroyOnClose
          open={showModal}
          footer={null}
          centered
          closable
          onCancel={() => setShowModal(false)}
          className="w-[608px] h-[363px]"
          bodyStyle={{
            backgroundColor: '#000000',
          }}
        >
          <div
            ref={iframeContainerRef}
          >
            <iframe
              ref={iframeRef}
              width={window.innerWidth - 40}
              src={YOUTUBE_LINK}
              title="Redefining how NFT is valued: Lithium Finance"
              frameBorder="0"
              allow={`
                accelerometer;
                autoplay;
                clipboard-write;
                encrypted-media;
                gyroscope;
                picture-in-picture
              `}
              allowFullScreen
            />
          </div>
        </Modal>
        <div className="z-50 flex flex-col">
          <Image
            className="py-8"
            src={CrowdWisdom}
            loading="eager"
            quality={50}
          />
          <Title level={2} type="secondary" className="mb-0 mt-4">
            How do you value
          </Title>
          <TypeWriter size="small" />
          <div className="text-lg font-thin font-[WorkSans] italic m-0 text-[#FFFCFB]">
            Harness the power of <span className="font-medium font-[WorkSans] italic m-0">Artificial Intelligence</span> and
            <span className="font-medium font-[WorkSans] italic m-0"> Crowd Wisdom</span> for expert guidance
          </div>
          <Link href="#ecosystem">
            <Button type="primary" className="mt-8">
              View Ecosystem
            </Button>
          </Link>
        </div>
        <FadingRectsBackgroundMd />
      </section>
    );
  }

  return (
    <section className="max-w-[1200px] mx-auto mt-20 justify-between flex relative">
      <div className="w-full flex justify-between z-10">
        <div className="z-10">
          <Title level={1} type="secondary" className="font-normal m-0">
            How do you value
          </Title>
          <TypeWriter />
          <div className="text-3xl font-thin font-[WorkSans] italic m-0 pt-8 text-[#FFFCFB]">
            Harness the power of <span className="font-medium font-[WorkSans] italic m-0">Artificial Intelligence</span> and
          </div>
          <div className="text-3xl font-thin font-[WorkSans] italic m-0 pt-4 text-[#FFFCFB]">
            <span className="font-medium font-[WorkSans] italic m-0">Crowd Wisdom</span> for expert guidance
          </div>
          <Link href="#ecosystem">
            <Button type="primary" className="mt-20 w-48">
              View Ecosystem
            </Button>
          </Link>
        </div>
        <Image
          src={CrowdWisdom}
          loading="eager"
          quality={50}
        />
      </div>
      <FadingRectsBackground />
    </section>
  );
}

export default BannerSection;
