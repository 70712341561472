import { node, objectOf, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';

function LinkRenderer({ href, children }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

LinkRenderer.propTypes = {
  href: string,
  children: node,
};

function UnorderedListRenderer({ children }) {
  return (
    <ul className="list-disc list-outside ml-6">
      {children}
    </ul>
  );
}

UnorderedListRenderer.propTypes = {
  children: node,
};

function OrderedListRenderer({ children }) {
  return (
    <ol className="list-decimal list-outside ml-6">
      {children}
    </ol>
  );
}

OrderedListRenderer.propTypes = {
  children: node,
};

export default function Markdown({ children, className, style }) {
  return (
    <ReactMarkdown
      className={className}
      style={style}
      components={{
        a: LinkRenderer,
        ul: UnorderedListRenderer,
        ol: OrderedListRenderer,
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

Markdown.propTypes = {
  children: node,
  className: string,
  style: objectOf(string),
};
