/* eslint-disable @next/next/no-img-element */
import { arrayOf, bool, func, node, number, string } from 'prop-types';
import { useCallback } from 'react';
import Markdown from 'components/markdown';
import Title from 'antd/lib/typography/Title';

function Phase({
  index,
  isActive,
  title,
  period,
  label,
  contents,
  checked,
  onClick,
}) {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <div className="relative">
      {index === 1 && (
        <div className={'absolute w-[70px] top-[45%] left-[-51px] md:left-[-72px]'}>
          <div className="rounded-full bg-white h-[20px] w-[20px]" />
          <img
            alt=""
            src="/static/images/landing/flag.png"
            className={'h-[125px] w-[70px] absolute left-[-34px] top-[-57px]'}
          />
        </div>
      )}
      {index === 1 && (
        <div className="flex justify-center">
          <div className={'absolute top-[-60px]'} />
        </div>
      )}
      <button
        type="button"
        key={index}
        onClick={handleClick}
        className={'rounded-2xl px-4 py-4 text-white text-left transition-all flex flex-col mr-6 w-full mb-6'}
        style={{
          backgroundColor: index === 0
            ? '#1E2024'
            : '#FF7826',
        }}
      >
        <div className="w-full flex justify-between">
          <div className={`${index === 0 ? 'text-gray' : 'text-[#2E2F3A]'} text-[16px] font-bold transition-all flex flex-col`}>
            <div>
              {index === 0 ? `Phase 1 & 2 / ${period}` : `Phase ${index + 2} / ${period}`}
            </div>
          </div>
          {label && (
            <div className="py-1 px-3 text-black bg-white font-bold self-center rounded-3xl text-[16px]">
              {label}
            </div>
          )}
        </div>
        <Title className={`font-semibold transition-all my-4 text-[32px] break-normal ${index === 0 ? 'text-white' : 'text-black'}`}>{title}</Title>
        <div className={`transition-all ${isActive ? 'mt-4 max-h-[560px] md:max-h-96 opacity-100' : 'mt-0 max-h-0 opacity-0 invisible'}`}>
          <ul
            className="mb-3 ml-6 list-outside list-none"
          >
            {
              contents.map((content) => (
                <li
                  key={`${title}-${content.slice(-10)}`}
                  className={`font-[WorkSans] flex items-start text-lg font-normal ${index === 0 ? 'text-white' : 'text-[#2E2F3A]'}`}
                >
                  {checked && <img alt="" src="/static/images/landing/checkmark.png" className="mt-1 mr-2" />}
                  <Markdown>
                    {content}
                  </Markdown>
                </li>
              ))
            }
          </ul>
        </div>
      </button>
    </div>
  );
}

Phase.propTypes = {
  index: number,
  isActive: bool,
  title: string.isRequired,
  label: string,
  period: string.isRequired,
  contents: arrayOf(node),
  onClick: func,
  checked: bool,
};

export default Phase;
