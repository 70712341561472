import { arrayOf, func, node, number } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

function PhasesMobile({
  step,
  children,
  onStepChange,
}) {
  const [focusedStep, setFocusedStep] = useState(step ?? 0);

  useEffect(() => {
    if (step !== focusedStep) {
      // eslint-disable-next-line no-unused-expressions
      setFocusedStep[step];
    }

    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleSetStep = useCallback((index) => {
    setFocusedStep(index);
    onStepChange(index);
  }, [onStepChange]);

  return (
    <div className="flex flex-col ml-[15px] w-full">
      {
        React.Children.map(children, (child, index) => (
          React.cloneElement(child, {
            index,
            isActive: index === focusedStep,
            onClick: handleSetStep,
          })
        ))
      }
    </div>
  );
}

PhasesMobile.propTypes = {
  step: number,
  children: arrayOf(node),
  onStepChange: func,
};

export default PhasesMobile;
