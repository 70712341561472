/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';
import Title from 'antd/lib/typography/Title';

// components
import Phase from 'components/home-page/phase';
import Phases from 'components/home-page/phases';

function Roadmap() {
  const [phase, setPhase] = useState(1);

  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <Title type="secondary" level={1} className="px-0 text-[32px] mb-6 font-semibold">
        Roadmap
      </Title>
      <div className="relative mb-8">
        <img
          alt=""
          src="/static/images/landing/arrow-bar.png"
        />
        <div className="absolute rounded-full bg-[#5B5C6C] top-[6px] h-[20px] w-[20px] left-[100px]" />
        <div className="absolute rounded-full bg-white top-[6px] h-[20px] w-[20px] right-[100px]" />
      </div>
      <Phases
        step={phase}
        onStepChange={setPhase}
      >
        <Phase
          title="Testnet & Mainnet Beta"
          period="2022"
          label="Done"
          checked
          contents={[
            'Price Expert Recruitment',
            'Pricing Algorithm & Reputation System',
            'Reward Mechanism & On-chain Distribution',
            'Proprietary Machine Learning',
            'NFT Full Collection Pricing & API',
            'Initial Partnerships',
          ]}
        />
        <Phase
          title="AI / DAO Updates"
          period="2023 1H"
          checked
          contents={[
            '**Artificial Intelligence x Crowd Wisdom Enhancement**',
            'New analytics tool demo',
            'Public access to Smart Contract functions',
            'Further protocol decentralization',
            'Support multichain NFT collection',
            '**NFT financialization & DAO transformation**',
            'DAO governance infrastructure setup',
            'NFT financialization sector partnership & integration',
          ]}
        />
        <Phase
          title="Empowering Community"
          period="2023 2H"
          contents={[
            '• Launch of DAO',
            '• Initial governance proposals',
            '• Multichain support for LITH on selected exchanges',
            '• Explore real-world application',
          ]}
        />
      </Phases>
    </section>
  );
}

export default Roadmap;
