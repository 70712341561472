/* eslint-disable @next/next/no-img-element */
import { Typography } from 'antd';
import { string } from 'prop-types';
import { useContext } from 'react';
import _ from 'lodash';

import WindowSizeContext from 'contexts/window-size';
import mixpanel from 'libs/mixpanel';

const { Title, Paragraph } = Typography;

export const TITLE_LIMIT = 70;
export const BLURB_LIMIT = 180;

export default function MediumItem({ title, blurb, href, img }) {
  const { xs } = useContext(WindowSizeContext);

  return (
    <li className="mr-8">
      <div className={`${xs ? 'w-[350px]' : 'w-[380px]'}`}>
        <img
          alt=""
          src={img}
          className="rounded-2xl border border-[#8687A0] border-solid"
        />
        <a
          className="flex items-center text-white hover:underline font-bold hover:text-[#E96036]"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.track(
              '👆🏼HIW Articles',
              { articleTitle: title },
              { send_immediately: true },
            );
          }}
        >
          <Title className="font-bold m-0 text-2xl text-white mt-4 hover:text-[#E96036]" level={3}>
            {_.truncate(title, { length: TITLE_LIMIT })}
          </Title>
        </a>
        <Paragraph className="text-white mt-2 font-[WorkSans]">
          {_.truncate(blurb, { length: BLURB_LIMIT })}
        </Paragraph>
      </div>
    </li>
  );
}

MediumItem.propTypes = {
  title: string.isRequired,
  blurb: string.isRequired,
  href: string.isRequired,
  img: string.isRequired,
};
