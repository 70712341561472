/* eslint-disable @next/next/no-img-element */
import { useContext, useState } from 'react';
import Image from 'next/image';
import Title from 'antd/lib/typography/Title';

// components
import PhaseMobile from 'components/home-page/phase-mobile';
import PhasesMobile from 'components/home-page/phases-mobile';

// contexts
import WindowSizeContext from 'contexts/window-size';

function Roadmap() {
  const [phase, setPhase] = useState(1);

  const { md, lg } = useContext(WindowSizeContext);

  let dots = 'left-[3px]';
  let bar = 'mx-[15px] min-w-[24px] max-w-[24px] w-[24px]';

  if (md || lg) {
    bar = 'ml-[20px] mr-[30px] min-w-[34px] max-w-[34px] w-[34px]';
    dots = 'left-[7px]';
  }

  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <Title type="secondary" level={2} className="px-0 text-[24px] mb-6 font-bold">
        Roadmap
      </Title>
      <div className="flex">
        <div className={`relative ${bar}`}>
          <Image
            layout="fill"
            loading="eager"
            alt=""
            src="/static/images/landing/arrow-bar-vertical.png"
            quality={50}
          />
          <div className={`absolute rounded-full bg-[#5B5C6C] top-[60px] h-[20px] w-[20px] ${dots}`} />
          <div className={`absolute rounded-full bg-white h-[20px] w-[20px] ${dots} bottom-[90px]`} />
        </div>
        <PhasesMobile
          step={phase}
          onStepChange={setPhase}
        >
          <PhaseMobile
            title="Testnet & Mainnet Beta"
            period="2022"
            label="Done"
            checked
            contents={[
              'Price Expert Recruitment',
              'Pricing Algorithm & Reputation System',
              'Reward Mechanism & On-chain Distribution',
              'Proprietary Machine Learning',
              'NFT Full Collection Pricing & API',
              'Initial Partnerships',
            ]}
          />
          <PhaseMobile
            title="AI / DAO Updates"
            period="2023 1H"
            checked
            contents={[
              '**Artificial Intelligence x Crowd Wisdom Enhancement**',
              'New analytics tool demo',
              'Public access to Smart Contract functions',
              'Further protocol decentralization',
              'Support multichain NFT collection',
              '**NFT financialization & DAO transformation**',
              'DAO governance infrastructure setup',
              'NFT financialization sector partnership & integration',
            ]}
          />
          <PhaseMobile
            title="Empowering Community"
            period="2023 2H"
            contents={[
              '• Launch of DAO',
              '• Initial governance proposals',
              '• Multichain support for LITH on selected exchanges',
              '• Explore real-world application',
            ]}
          />
        </PhasesMobile>
      </div>
    </section>
  );
}

export default Roadmap;
