import { Button } from 'antd';
import ExternalLink from 'components/external-link';
import Image from 'next/image';
import Link from 'next/link';

import { useContext } from 'react';
import WindowSizeContext from 'contexts/window-size';

import Forum from '../../../public/static/images/landing/forum.svg';
import Governance from '../../../public/static/images/landing/governance.svg';
import Proposals from '../../../public/static/images/landing/proposals.svg';
import Voting from '../../../public/static/images/landing/voting.svg';

function DAO() {
  const { xs, sm } = useContext(WindowSizeContext);
  if (xs || sm) {
    return (
      <section className="max-w-[1200px] mx-auto mt-20 justify-between flex relative">
        <div id="DAO" className="text-[#FFFCFB] flex flex-col w-full">
          <span className="text-xl font-bold pb-8">AI & Crowd Wisdom</span>
          <span className="font-normal font-[WorkSans] text-sm pb-4">
            {'Lithium Finance DAO is a Decentralized Autonomous Organization (DAO) that governs and manages the protocol through the voting power of governance token (LITH).'}
          </span>
          <div className="flex flex-col gap-8">
            <div className="rounded-xl bg-[#1E2024] p-8 flex flex-col w-full">
              <div className="w-full h-[300px] relative flex items-center justify-center">
                <Image src={Governance} layout="fill" />
              </div>
              <div className="flex flex-col">
                <span className="text-2xl font-bold py-4">Governance Process</span>
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] pb-4">
                  {'Explore how decisions are made in Lithium Finance DAO by the community of LITH token holders.'}
                </p>
                <Link
                  href="https://docs.lith.finance/governance/lithium-dao"
                  passHref
                >
                  <Button
                    type="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Lithium Finance DAO
                  </Button>
                </Link>
              </div>
            </div>
            <div className="rounded-xl bg-[#1E2024] p-8 flex flex-col w-full">
              {/* forum */}
              <div className="relative flex items-center gap-4">
                <Image src={Forum} height="50px" width="50px" layout="fixed" />
                <ExternalLink
                  href="https://forum.lith.finance"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  <span className="text-2xl font-bold">Forum</span>
                </ExternalLink>
              </div>
              <div className="h-full flex flex-col justify-between">
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Discuss and share governance-related ideas and proposal with the community. '}
                </p>
              </div>
            </div>
            {/* Proposals */}
            <div className="rounded-xl bg-[#1E2024] p-8 flex flex-col w-full">
              <div className="relative flex items-center gap-4">
                <Image src={Proposals} height="50px" width="50px" layout="fixed" />
                <ExternalLink
                  href="https://forum.lith.finance/c/proposals"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  Proposals
                </ExternalLink>
              </div>
              <div className="h-full flex flex-col justify-between">
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Stay up-to-date and browse all the major proposals and decisions. '}
                </p>
              </div>
            </div>
            {/* Voting */}
            <div className="rounded-xl bg-[#1E2024] p-8 flex flex-col w-full">
              <div className="relative flex items-center gap-4">
                <Image src={Voting} height="50px" width="50px" layout="fixed" />
                <ExternalLink
                  href="https://snapshot.org/#/lithfinance.eth"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  Voting
                </ExternalLink>
              </div>
              <div className="h-full flex flex-col justify-between">
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Cast your vote to all proposals to shape the growth of Lithium Finance DAO. '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="max-w-[1200px] mx-auto mt-20 justify-between flex relative">
      <div id="DAO" className="text-[#FFFCFB] flex flex-col">
        <span className="text-3xl font-bold pb-8">Lithium Finance DAO</span>
        <p className="font-normal font-[WorkSans] text-lg pb-8">
          {'Lithium Finance DAO is a Decentralized Autonomous Organization (DAO) that governs and manages the protocol through the voting power of governance token (LITH).'}
        </p>
        <div className="flex gap-8">
          <div className="w-1/2 rounded-xl bg-[#1E2024] p-8 flex flex-col">
            <div className="w-full h-[300px] relative flex items-center justify-center">
              <Image src={Governance} layout="fill" />
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-bold py-4">Governance Process</span>
              <p className="text-[#D2D3DD] font-normal font-[WorkSans] pb-4">
                {'Explore how decisions are made in Lithium Finance DAO by the community of LITH token holders. '}
              </p>
              <Link
                href="https://docs.lith.finance/governance/lithium-dao"
                passHref
              >
                <Button
                  type="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Lithium Finance DAO
                </Button>
              </Link>
            </div>
          </div>
          <div className="w-1/2 flex flex-col gap-8">
            {/* forum */}
            <div className="w-full rounded-xl bg-[#1E2024] flex h-1/3 px-8 py-8 gap-8">
              <div className="w-[100px] h-[100px] relative">
                <Image src={Forum} height={100} width={100} layout="fixed" />
              </div>
              <div className="h-full flex flex-col justify-between items-start">
                <ExternalLink
                  href="https://forum.lith.finance"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  Forum
                </ExternalLink>
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Discuss and share governance-related ideas and proposal with the community. '}
                </p>
              </div>
            </div>
            {/* Proposals */}
            <div className="w-full rounded-xl bg-[#1E2024] flex h-1/3 px-8 py-8 gap-8">
              <div className="w-[100px] h-[100px] relative">
                <Image src={Proposals} height={100} width={100} layout="fixed" />
              </div>
              <div className="h-full flex flex-col justify-between">
                <ExternalLink
                  href="https://forum.lith.finance/c/proposals"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  Proposals
                </ExternalLink>
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Stay up-to-date and browse all the major proposals and decisions. '}
                </p>
              </div>
            </div>
            {/* Voting */}
            <div className="w-full rounded-xl bg-[#1E2024] flex h-1/3 px-8 py-8 gap-8">
              <div className="w-[100px] h-[100px] relative">
                <Image src={Voting} height={100} width={100} layout="fixed" />
              </div>
              <div className="h-full flex flex-col justify-between">
                <ExternalLink
                  href="https://snapshot.org/#/lithfinance.eth"
                  className="text-2xl font-bold flex items-center p-0 gap-1"
                >
                  Voting
                </ExternalLink>
                <p className="text-[#D2D3DD] font-normal font-[WorkSans] m-0">
                  {'Cast your vote to all proposals to shape the growth of Lithium Finance DAO. '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DAO;
