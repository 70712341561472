/* eslint-disable @next/next/no-img-element */
import { arrayOf, bool, func, node, number, string } from 'prop-types';
import { useCallback } from 'react';
import Markdown from 'components/markdown';
import Title from 'antd/lib/typography/Title';

function Phase({
  index,
  isActive,
  title,
  period,
  label,
  contents,
  onClick,
  checked,
}) {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  let conditional;
  if (isActive) {
    conditional = 'flex-col w-[584px]';
  } else {
    conditional = 'w-[276px]';
  }
  return (
    <div className="relative">
      {index === 1 && (
        <div className="flex justify-center">
          <div className={'absolute top-[-60px]'}>
            <div className="relative w-[70px]">
              <div className="rounded-full bg-white h-[20px] w-[20px]" />
              <img
                alt=""
                src="/static/images/landing/flag.png"
                className={'h-[125px] w-[70px] absolute top-[-57px] left-[-34px]'}
              />
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        key={index}
        onClick={handleClick}
        onMouseOver={handleClick}
        onFocus={handleClick}
        className={`h-full rounded-2xl px-4 py-4 text-white text-left transition-all flex flex-col mr-6 ${conditional}`}
        style={{
          backgroundColor: index === 0
            ? '#1E2024'
            : '#FF7826',
        }}
      >
        <div className="w-full flex justify-between">
          <div className={`${index === 0 ? 'text-gray' : 'text-[#2E2F3A]'} text-2xl font-bold transition flex flex-col duration-100`}>
            <div>
              {index === 0 ? 'Phase 1 & 2' : `Phase ${index + 2}`}
            </div>
            <div>
              {period}
            </div>
          </div>
          {label && (
            <div className="py-1 px-2 text-black bg-white font-bold self-center rounded-2xl text-2xl">
              {label}
            </div>
          )}
        </div>
        <Title
          className={`font-semibold transition-all my-4 text-[56px] ${index === 0 ? 'text-white' : 'text-black'}`}
          style={{ wordBreak: 'break-word' }}
        >
          {title}
        </Title>
        <div className={`transition-all ${isActive ? 'mt-4 max-h-[560px] md:max-h-96 opacity-100' : 'mt-0 max-h-0 opacity-0 invisible'}`}>
          <ul
            className="mb-3 ml-6 list-outside list-none"
          >
            {
              contents.map((content, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className={`font-[WorkSans] text-lg font-normal flex items-center ${index === 0 ? 'text-white' : 'text-[#2E2F3A]'}`}>
                  {checked && <img alt="" src="/static/images/landing/checkmark.png" className="mr-2 mb-4" />}
                  <Markdown>
                    {content}
                  </Markdown>
                </li>
              ))
            }
          </ul>
        </div>
      </button>
    </div>
  );
}

Phase.propTypes = {
  index: number,
  isActive: bool,
  title: string.isRequired,
  label: string,
  period: string.isRequired,
  contents: arrayOf(node),
  onClick: func,
  checked: bool,
};

export default Phase;
