import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { Button, Typography } from 'antd';
import MediumItem from 'components/home-page/medium-item';

const { Title } = Typography;

export const LIST = [
  {
    title: 'Lithium Finance: First Decentralized NFT Valuation Protocol',
    blurb: 'We are proud to announce that we have launched our new website in anticipation of our Mainnet Beta launch which is happening soon! Lithium Finance is the first decentralized NFT valuation protocol powered by collective intelligence and machine learning. The protocol aims to redefine how NFTs are being valued through incentivizing...',
    href: 'https://medium.com/lithiumfinance/lithium-finance-first-decentralized-nft-valuation-protocol-d36fd382298a',
    img: 'https://miro.medium.com/max/1400/1*cykRwIEhrwsgL3_zQpHACQ.jpeg',
  },
  {
    title: 'Our Product Mechanics & Tokenomics',
    blurb: 'Our mechanism and crypto-economy in our own Token — LITH. At the heart of the Lithium Protocol is LITH, Lithium’s native token from which stem all interactions and rewards that are to happen within the ecosystem.',
    href: 'https://medium.com/lithiumfinance/our-product-mechanics-tokenomics-f5e3e7db2e8b',
    img: 'https://miro.medium.com/max/1400/1*s5LocyBx5yvqeBy9C_PDMg.jpeg',
  },
  {
    title: 'Collective Intelligence: NFT Pricing as a Beauty Contest',
    blurb: 'Lithium Finance’s proprietary algorithm incorporates human-based inputs alongside Machine Learning to provide accurate NFT valuations, and this is achieved by incentivizing price experts with rewards when they add reliable inputs to the system. Despite the sound assumption, we soon ran into two logical issues.',
    href: 'https://medium.com/lithiumfinance/collective-intelligence-nft-pricing-as-a-beauty-contest-bbfae1b7f8cd',
    img: 'https://miro.medium.com/max/1400/1*LviNmwSUbcj4Rhn24uoEng.jpeg',
  },
  {
    title: 'Collective Intelligence: How do we produce consensus value?',
    blurb: 'At Lithium we believe in the superiority of our valuation model and its fundamental principle: Collective Intelligence. Across the ages, society has trusted the wisdom of the crowd where they believe in the collective knowledge of a group as expressed through their aggregated opinions. Consensus from multiple predictions are generally preferred to a single, isolated prediction.',
    href: 'https://medium.com/lithiumfinance/collective-intelligence-how-do-we-produce-consensus-value-f8f6b21a2182',
    img: 'https://miro.medium.com/max/1400/1*tH0wk8SYErRCYsTkWGlnFw.webp',
  },
  {
    title: 'Testnet to Mainnet',
    blurb: 'With the overwhelming support from the community during the Testnet phase, we are getting closer to the Mainnet Beta launch!',
    href: 'https://medium.com/lithiumfinance/testnet-to-mainnet-e0a95694da81',
    img: 'https://miro.medium.com/max/1400/1*os5K--CdqqOpE1ttCQXSwQ.png',
  },
];

export const HIW_LIST = [
  {
    title: 'Product Mechanics & Tokenomics',
    blurb: 'Lithium is a decentralised valuation protocol combining the power of both Human and Machine Learning into one dynamic and potent pricing model for illiquid assets across the Web3 and DeFi space...',
    href: 'https://medium.com/lithiumfinance/our-product-mechanics-tokenomics-f5e3e7db2e8b',
    img: 'https://miro.medium.com/max/4800/1*s5LocyBx5yvqeBy9C_PDMg.webp',
  },
  // TO ADD Link 2: Mainnet Beta Step by Step
  {
    title: 'How are the final values calculated?',
    blurb: 'At Lithium we believe in the superiority of our valuation model and its fundamental principle: Collective Intelligence. Across the ages, society has trusted the wisdom of the crowd where they believe in the collective knowledge of a group as expressed through their aggregated opinions. Consensus from multiple predictions are generally preferred to a single, isolated prediction...',
    href: 'https://medium.com/lithiumfinance/collective-intelligence-how-do-we-produce-consensus-value-f8f6b21a2182',
    img: 'https://miro.medium.com/max/700/1*tH0wk8SYErRCYsTkWGlnFw.jpeg',
  },
  {
    title: 'What are the winning criterias?',
    blurb: 'Lithium Finance provides NFT valuation swiftly with a combination of human input and machine learning. Here comes the big question: how do you win and earn rewards on the platform?...',
    href: 'https://medium.com/lithiumfinance/pricing-quest-what-are-the-winning-criterias-27f4f55cc870',
    img: 'https://miro.medium.com/max/1400/1*TpTpuC32qpqoSMx8CWpbCA.jpeg',
  },
  {
    title: 'Price NFT as Beauty Contest',
    blurb: 'Lithium Finance’s proprietary algorithm incorporates human-based inputs alongside Machine Learning to provide accurate NFT valuations, and this is achieved by incentivizing price experts with rewards when they add reliable inputs to the system. Despite the sound assumption, we soon ran into two logical issues...',
    href: 'https://medium.com/lithiumfinance/collective-intelligence-nft-pricing-as-a-beauty-contest-bbfae1b7f8cd',
    img: 'https://miro.medium.com/max/1400/1*LviNmwSUbcj4Rhn24uoEng.jpeg',
  },
];

function LearnMore() {
  return (
    <section className="max-w-[1200px] mx-auto mt-20">
      <div className="flex items-center justify-between mb-4">
        <Title type="secondary" level={2} className="font-bold text-[32px] mb-0">
          Learn More
        </Title>
        <Button
          type="text"
          href="https://medium.com/lithiumfinance"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
          size="large"
        >
          Open Medium
          <ArrowLongRightIcon className="ml-0.5 h-5 w-5" />
        </Button>
      </div>
      <ul className="flex overflow-x-scroll">
        {LIST.map((d) => (<MediumItem {...d} key={d.img} />))}
      </ul>
    </section>
  );
}

export default LearnMore;
