/* eslint-disable @next/next/no-img-element */
import { Space } from 'antd';
import { string } from 'prop-types';
import { useContext } from 'react';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import styles from 'components/home-page/partners.module.css';

// contexts
import WindowSizeContext from 'contexts/window-size';

const ROW_1 = [
  {
    href: 'https://www.newmancapital.com/',
    src: 'newman.png',
    name: 'newman-capital',
  },
  {
    href: 'https://www.fbg.capital/',
    src: 'fbg.png',
    name: 'fbg',
  },
  {
    href: 'https://ngc.fund/home',
    src: 'NGCventures.png',
    name: 'NGCventures',
  },
  {
    href: 'https://gbv.capital/',
    src: 'gbv.png',
    name: 'gbv',
  },
  {
    href: 'https://kenetic.capital/',
    src: 'kenetic.png',
    name: 'kenetic',
  },
  {
    href: 'https://shima.capital/',
    src: 'shima.png',
    name: 'shima',
  },
  {
    href: 'https://petrock.capital/',
    src: 'petrock.png',
    name: 'petrock',
  },
  {
    href: 'https://www.vessel.vc/',
    src: 'vessel.png',
    name: 'vessel',
  },
  {
    href: 'https://palm.io/',
    src: 'palm-.png',
    name: 'palm-',
  },
];

const ROW_2 = [
  {
    href: 'https://injectiveprotocol.com/',
    src: 'injective.png',
    name: 'injective',
  },
  {
    href: 'https://waggle.network/',
    src: 'waggle.png',
    name: 'waggle',
  },
  {
    href: 'https://truefi.io/',
    src: 'trufi.png',
    name: 'trufi',
  },
  {
    href: 'https://www.genshards.com/',
    src: 'genshards.png',
    name: 'genshards',
  },
  {
    href: 'https://www.blitz-labs.xyz/',
    src: 'blitz.png',
    name: 'blitz',
  },
  {
    href: 'https://www.credefi.finance/',
    src: 'credifi.png',
    name: 'credifi',
  },
  {
    href: 'https://clearpool.finance/',
    src: 'clearpool.jpg',
    name: 'clearpool',
  },
  {
    href: 'https://bitkeep.com/en/index/',
    src: 'bitkeep.svg',
    name: 'bitkeep',
  },
  {
    href: 'https://www.usecyan.com/',
    src: 'cyan.svg',
    name: 'cyan',
  },
];

const ROW_3 = [
  {
    href: 'https://cere.network/',
    src: 'cere.png',
    name: 'cere',
  },
  {
    href: 'https://danu.fi/',
    src: 'danu.svg',
    name: 'danu',
  },
  {
    href: 'https://www.degex.com/',
    src: 'degex.svg',
    name: 'degex',
  },
  {
    href: 'https://zbs.capital/',
    src: 'zbs.png',
    name: 'zbs',
  },
  {
    href: 'https://duckdao.io/',
    src: 'duckdao.png',
    name: 'duckdao',
  },
  {
    href: 'https://ellipti.io/',
    src: 'ellipti.png',
    name: 'ellipti',
  },
  {
    href: 'https://www.gains-associates.com/',
    src: 'gains.png',
    name: 'gains',
  },
  {
    href: 'https://www.div.vc/',
    src: 'divergeence.png',
    name: 'divergeence',
  },
  {
    href: 'https://www.astronaut.capital/',
    src: 'astronut.png',
    name: 'astronut',
  },
];

const ROW_4 = [
  {
    href: 'https://www.soulcapital.vc/',
    src: 'soul.png',
    name: 'soul',
  },
  {
    href: 'https://kyros.ventures/',
    src: 'kyros.png',
    name: 'kyros',
  },
  {
    href: 'https://www.autonomy.capital/',
    src: 'autonomy.png',
    name: 'autonomy',
  },
  {
    href: 'https://www.vendetta.capital/',
    src: 'vendetta.png',
    name: 'vendetta',
  },
  {
    href: 'https://www.7oclockcapital.com/',
    src: '7oclock.png',
    name: '7oclock',
  },
  {
    href: 'https://catchervc.chaincatcher.com/',
    src: 'catchervc.png',
    name: 'catchervc',
  },
  {
    href: 'https://deri.io/',
    src: 'deri-protocol.svg',
    name: 'deri-protocol',
  },
  {
    href: 'https://sgnal.xyz/',
    src: 'sgnal.svg',
    name: 'sgnal',
  },
  {
    href: 'https://strip.finance/',
    src: 'strip-finance.svg',
    name: 'strip-finance',
  },
];

function Item({
  name,
  href,
  src,
}) {
  return (
    <div
      className="rounded-3xl border-white border border-solid h-[50px] w-[180px] mx-2 flex items-center justify-center"
    >
      <a
        className=""
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        key={name}
      >
        <img
          className="px-2 h-[35px]"
          alt={name}
          src={`/static/images/investors/${src}`}
        />
      </a>
    </div>
  );
}

Item.propTypes = {
  name: string.isRequired,
  href: string.isRequired,
  src: string.isRequired,
};

function Partners() {
  const { xs, sm } = useContext(WindowSizeContext);

  return (
    <section className="mt-20">
      <Title type="secondary" level={2} className={`max-w-[1200px] mx-auto flex items-center font-bold ${sm || xs ? 'text-[24px]' : 'text-[32px]'}`}>
        Ecosystem Partners
      </Title>
      <div className="max-w-[1200px] h-[270px] mx-auto overflow-hidden">
        <Space className={`flex mt-6 overflow-visible justify-start ${styles.rightToLeft} ${styles.marquee}`}>
          {_.times(3).map((i) => ROW_1.map((d) => (<Item {...d} key={`${d.name}-${i}`} />)))}
        </Space>
        <Space className={`flex mt-6 overflow-visible justify-end ${styles.leftToRight} ${styles.marquee}`}>
          {_.times(3).map((i) => ROW_2.map((d) => (<Item {...d} key={`${d.name}-${i}`} />)))}
        </Space>
        <Space className={`flex mt-6 overflow-visible justify-start ${styles.rightToLeft} ${styles.marquee}`}>
          {_.times(3).map((i) => ROW_3.map((d) => (<Item {...d} key={`${d.name}-${i}`} />)))}
        </Space>
        <Space className={`flex mt-6 overflow-visible justify-end ${styles.leftToRight} ${styles.marquee}`}>
          {_.times(3).map((i) => ROW_4.map((d) => (<Item {...d} key={`${d.name}-${i}`} />)))}
        </Space>
      </div>
    </section>
  );
}

export default Partners;
