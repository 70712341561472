import { arrayOf, func, node, number } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

// contexts
import WindowSizeContext from 'contexts/window-size';

function Phases({
  step,
  children,
  onStepChange,
}) {
  const [focusedStep, setFocusedStep] = useState(step ?? 0);

  const { xs, sm } = useContext(WindowSizeContext);

  useEffect(() => {
    if (step !== focusedStep) {
      // eslint-disable-next-line no-unused-expressions
      setFocusedStep[step];
    }

    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleSetStep = useCallback((index) => {
    setFocusedStep(index);
    onStepChange(index);
  }, [onStepChange]);

  return (
    <div className={`flex ${xs || sm ? 'flex-col' : ''}`}>
      {
        React.Children.map(children, (child, index) => (
          React.cloneElement(child, {
            index,
            isActive: index === focusedStep,
            onClick: handleSetStep,
          })
        ))
      }
    </div>
  );
}

Phases.propTypes = {
  step: number,
  children: arrayOf(node),
  onStepChange: func,
};

export default Phases;
