import { CURRENT_USER } from 'libs/react-query/constants';
import { QueryClient, dehydrate } from 'react-query';
import { useContext, useEffect } from 'react';
import mixpanel from 'libs/mixpanel';

// ssr
import { getCurrentUser } from 'api/ssr/user';

// components
import BannerSection from 'components/home-page/banner-section';
import DAO from 'components/home-page/dao';
import Faq from 'components/home-page/faq';
import HowItWorks from 'components/home-page/how-it-works';
import LearnMore from 'components/home-page/learn-more';
import LearnMoreMobile from 'components/home-page/learn-more-mobile';
import Partners from 'components/home-page/partners';
import Roadmap from 'components/home-page/roadmap';
import RoadmapMobile from 'components/home-page/roadmap-mobile';
import SeenOn from 'components/home-page/seen-on';
import Stats from 'components/home-page/stats';
import UseCases from 'components/home-page/use-cases';

// contexts
import WindowSizeContext from 'contexts/window-size';

export async function getServerSideProps(ctx) {
  const jwt = ctx.req.cookies.user;
  const queryClient = new QueryClient();

  await Promise.all([
    queryClient.prefetchQuery(CURRENT_USER, () => getCurrentUser(jwt)),
  ]);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
}

function Home() {
  const { xs, sm, md, lg } = useContext(WindowSizeContext);

  useEffect(() => {
    mixpanel.track('👀 Homepage');
  }, []);

  return (
    <div className="bg-black px-[4%] pb-[100px] md:pb-[160px] ">
      <BannerSection />
      <Stats />
      <HowItWorks />
      <SeenOn />
      <UseCases />
      <DAO />

      {xs || sm || md || lg ? <RoadmapMobile /> : <Roadmap />}
      <Partners />
      {xs || sm ? <LearnMoreMobile /> : <LearnMore />}

      <Faq />
    </div>
  );
}

export default Home;
